import React from "react";

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
        <i class="bx bx-award about__icon"></i>
        <h3 className="about__title">Frontend</h3>
        <span className="about__subtitle">6 Month </span>
      </div>

      <div className="about__box">
        <i class="bx bx-briefcase-alt about__icon"></i>
        <h3 className="about__title">Completed</h3>
        <span className="about__subtitle">14 + Projects</span>
      </div>

      <div className="about__box">
        <i class="bx bx-code about__icon"></i>
        <h3 className="about__title">Backend</h3>
        <span className="about__subtitle">2 month</span>
      </div>
    </div>
  );
};

export default Info;
